import AuthComponent from './AuthComponent'

const HomePage = () => {
  return (
    <div>
      <AuthComponent />
    </div>
  )
}

export default HomePage
