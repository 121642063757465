import React, { useState, useRef, useEffect } from 'react'
import './Camera.css'
import loading from '../data/amalie-steiness.gif'
import SelectionArea from './SelectionArea' // Импортируем новый компонент

const Camera = ({ updateResponse, email, setCoins }) => {
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const [videoStream, setVideoStream] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isFlashOn, setIsFlashOn] = useState(false)
  const [isSelectingArea, setIsSelectingArea] = useState(false)
  const [selectionArea, setSelectionArea] = useState({
    x: 100,
    y: 100,
    width: 200,
    height: 150,
  })
  const videoElement = useRef(null)

  const openCamera = () => {
    const constraints = {
      video: { facingMode: 'environment' },
    }
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        if (videoElement.current) {
          videoElement.current.srcObject = stream
        }
        setIsCameraOpen(true)
        setVideoStream(stream)
      })
      .catch((error) => {
        console.error('Error accessing camera:', error)
        alert('Failed to open camera: ' + error.message)
      })
  }

  const closeCamera = () => {
    if (videoStream) {
      videoStream.getTracks().forEach((track) => track.stop())
    }
    if (videoElement.current) {
      videoElement.current.srcObject = null
    }
    setIsCameraOpen(false)
    setIsLoading(false)
    setIsSelectingArea(false)
  }

  const toggleFlashlight = () => {
    if (videoStream) {
      const track = videoStream.getVideoTracks()[0]
      const capabilities = track.getCapabilities()
      if (capabilities.torch) {
        const constraints = {
          advanced: [{ torch: !isFlashOn }],
        }
        track.applyConstraints(constraints)
        setIsFlashOn(!isFlashOn)
      } else {
        alert('Flashlight is not supported on this device.')
      }
    }
  }

  const captureImage = () => {
    const canvas = document.createElement('canvas')
    const video = videoElement.current
    if (video) {
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      const context = canvas.getContext('2d')
      context.drawImage(video, 0, 0)
      const imageDataUrl = canvas.toDataURL('image/jpeg')
      setIsLoading(true)
      sendImageDataToServer(imageDataUrl)
    }
  }

  const captureSelectedArea = () => {
    const canvas = document.createElement('canvas')
    const video = videoElement.current
    if (video) {
      const { x, y, width, height } = selectionArea
      const scaleX = video.videoWidth / video.clientWidth
      const scaleY = video.videoHeight / video.clientHeight
      canvas.width = width * scaleX
      canvas.height = height * scaleY
      const context = canvas.getContext('2d')
      context.drawImage(
        video,
        x * scaleX,
        y * scaleY,
        width * scaleX,
        height * scaleY,
        0,
        0,
        width * scaleX,
        height * scaleY
      )
      const imageDataUrl = canvas.toDataURL('image/jpeg')
      setIsLoading(true)
      sendImageDataToServer(imageDataUrl)
    }
  }

  const sendImageDataToServer = (imageDataUrl) => {
    const byteString = atob(imageDataUrl.split(',')[1])
    const mimeString = imageDataUrl.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    const blob = new Blob([ab], { type: mimeString })
    const formData = new FormData()
    formData.append('image', blob, 'photo.jpeg')
    formData.append('email', email)
    fetch('https://lawmind.pro:5000/upload_image', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        const fullResponse = {
          response: data.text,
        }
        const errorResponse = {
          response: 'Error',
        }
        updateResponse(fullResponse || errorResponse)
        console.log(email)
        setCoins(data.remaining)
        setIsLoading(false)
        closeCamera()
      })
      .catch((error) => {
        console.error('Error sending image:', error.message)
        setIsLoading(false)
        closeCamera()
      })
  }

  const handleStart = (e) => {
    // e.preventDefault()
    const startX =
      (e.touches ? e.touches[0].clientX : e.clientX) - selectionArea.x
    const startY =
      (e.touches ? e.touches[0].clientY : e.clientY) - selectionArea.y

    const handleMove = (moveEvent) => {
      const x =
        (moveEvent.touches ? moveEvent.touches[0].clientX : moveEvent.clientX) -
        startX
      const y =
        (moveEvent.touches ? moveEvent.touches[0].clientY : moveEvent.clientY) -
        startY
      setSelectionArea((prev) => ({
        ...prev,
        x: Math.max(
          0,
          Math.min(x, videoElement.current.clientWidth - prev.width)
        ),
        y: Math.max(
          0,
          Math.min(y, videoElement.current.clientHeight - prev.height)
        ),
      }))
    }

    const handleEnd = () => {
      document.removeEventListener('mousemove', handleMove)
      document.removeEventListener('mouseup', handleEnd)
      document.removeEventListener('touchmove', handleMove)
      document.removeEventListener('touchend', handleEnd)
    }

    document.addEventListener(e.touches ? 'touchmove' : 'mousemove', handleMove)
    document.addEventListener(e.touches ? 'touchend' : 'mouseup', handleEnd)
  }

  const handleResizeStart = (resizeEvent) => {
    resizeEvent.stopPropagation()
    const startX = resizeEvent.touches
      ? resizeEvent.touches[0].clientX
      : resizeEvent.clientX
    const startY = resizeEvent.touches
      ? resizeEvent.touches[0].clientY
      : resizeEvent.clientY
    const startWidth = selectionArea.width
    const startHeight = selectionArea.height

    const handleResizeMove = (moveEvent) => {
      const newWidth = Math.max(
        50,
        startWidth +
          (moveEvent.touches
            ? moveEvent.touches[0].clientX
            : moveEvent.clientX) -
          startX
      )
      const newHeight = Math.max(
        50,
        startHeight +
          (moveEvent.touches
            ? moveEvent.touches[0].clientY
            : moveEvent.clientY) -
          startY
      )
      setSelectionArea((prev) => ({
        ...prev,
        width: newWidth,
        height: newHeight,
      }))
    }

    const handleResizeEnd = () => {
      document.removeEventListener('mousemove', handleResizeMove)
      document.removeEventListener('mouseup', handleResizeEnd)
      document.removeEventListener('touchmove', handleResizeMove)
      document.removeEventListener('touchend', handleResizeEnd)
    }

    document.addEventListener(
      resizeEvent.touches ? 'touchmove' : 'mousemove',
      handleResizeMove
    )
    document.addEventListener(
      resizeEvent.touches ? 'touchend' : 'mouseup',
      handleResizeEnd
    )
  }

  useEffect(() => {
    if (isCameraOpen) {
      openCamera()
    }
    return () => {
      if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop())
      }
    }
  }, [isCameraOpen])

  return (
    <div>
      <button
        className='cameraButton'
        onClick={() => setIsCameraOpen((prev) => !prev)}
      ></button>

      {isCameraOpen && (
        <div className={`CameraModal ${isCameraOpen ? 'open' : ''}`}>
          <div
            className='CameraModalContent'
            onClick={(e) => e.stopPropagation()}
          >
            <div className='focus-frame'>
              <div className='left-ruler'>
                <div className='tick long' style={{ top: '0%' }}></div>
                <div className='tick short' style={{ top: '20%' }}></div>
                <div className='tick short' style={{ top: '40%' }}></div>
                <div className='tick short' style={{ top: '60%' }}></div>
                <div className='tick short' style={{ top: '80%' }}></div>
                <div className='tick long' style={{ top: '99.5%' }}></div>
              </div>
            </div>
            <div className='focus-frame-top-right'></div>
            <div className='focus-frame-bottom-left'></div>
            <div className='focus-frame-bottom-right'></div>
            {isLoading ? (
              <img src={loading} alt='Loading...' className='loadingGif' />
            ) : (
              <div className='video-wrapper'>
                <video ref={videoElement} autoPlay playsInline />
                <SelectionArea
                  selectionArea={selectionArea}
                  setSelectionArea={setSelectionArea}
                  isSelectingArea={isSelectingArea}
                  handleStart={handleStart}
                  handleResizeStart={handleResizeStart}
                />
              </div>
            )}
            <div>
              {!isLoading && (
                <>
                  <div className='controls'>
                    <button
                      className={`flashlightButton ${
                        isFlashOn ? 'flashlight-off' : 'flashlight-on'
                      }`}
                      onClick={toggleFlashlight}
                    ></button>
                    <button
                      className='captureButton'
                      onClick={
                        isSelectingArea ? captureSelectedArea : captureImage
                      }
                    ></button>
                    <button
                      className='selectAreaButton'
                      onClick={() => setIsSelectingArea(!isSelectingArea)}
                    >
                      {/* {isSelectingArea ? 'Cancel Area' : 'Select Area'} */}
                    </button>
                    {/* {isSelectingArea && (
                    <button
                      className='captureButton'
                      onClick={captureSelectedArea}
                    >
                      Capture Selected Area
                    </button>
                  )} */}
                  </div>
                  <div>
                    <button
                      className='cancelButton'
                      onClick={closeCamera}
                    ></button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Camera
