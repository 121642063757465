import React, { useState } from 'react'
import './FileUpload.css'
import uploadIcon from '../data/upload-photo-svgrepo-com.svg'
import loadingGif from '../data/loading.gif'
import modal_audio_upload from '../data/upload-file-svgrepo-com.svg'
import Modal from './Modal'

const FileUpload = ({ updateResponse, coins, setCoins, email }) => {
  const [file, setFile] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Обработка выбора файла
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    if (selectedFile) {
      setFile(selectedFile)
      setIsModalOpen(true)
      event.target.value = null // Сбрасываем input после выбора файла
    }
  }

  // Обработка загрузки файла на сервер
  const handleSubmit = async () => {
    if (!file) {
      alert('Please select a file')
      return
    }

    setIsLoading(true)
    const formData = new FormData()
    formData.append('image', file)
    formData.append('email', email)

    try {
      const response = await fetch('https://lawmind.pro:5000/upload_image', {
        method: 'POST',
        body: formData,
      })

      const data = await response.json()
      const fullResponse = {
        response: data.text,
      }
      const errorResponse = {
        response: 'Error',
      }
      updateResponse(fullResponse || errorResponse)
      setCoins(data.remaining)
      console.log(email)
    } catch (error) {
      updateResponse({ error: `File upload error: ${error.message}` })
    } finally {
      setIsLoading(false)
      setIsModalOpen(false)
      setFile(null)
    }
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <div>
      {/* Иконка для загрузки файла */}
      <label htmlFor='file-input' className='uploadIcon'>
        <img src={uploadIcon} alt='Upload file' />
      </label>
      <input
        id='file-input'
        type='file'
        accept='image/*' // Ограничиваем выбор только изображениями
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      {/* Модальное окно для загрузки через внешний компонент Modal */}
      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        {/* Контент внутри модального окна */}
        {isLoading ? (
          <img
            src={loadingGif}
            alt='Loading...'
            className='fileUploadLoadingGif'
          />
        ) : (
          <>
            <h4>{file && file.name}</h4>
            <div onClick={handleSubmit} className='modalUploadFile'>
              <img src={modal_audio_upload} alt='Upload' />
              Upload
            </div>
          </>
        )}
      </Modal>
    </div>
  )
}

export default FileUpload
