import React, { useRef } from 'react'
import './SelectionArea.css'

const SelectionArea = ({
  selectionArea,
  setSelectionArea,
  isSelectingArea,
  handleStart,
  handleResizeStart,
}) => {
  const selectionRef = useRef(null)

  return (
    isSelectingArea && (
      <>
        <div
          ref={selectionRef}
          className='selection-area'
          style={{
            left: selectionArea.x,
            top: selectionArea.y,
            width: selectionArea.width,
            height: selectionArea.height,
          }}
          onMouseDown={handleStart}
          onTouchStart={handleStart}
        >
          <div
            className='resize-handle'
            onMouseDown={handleResizeStart}
            onTouchStart={handleResizeStart}
          ></div>
        </div>

        {/* Overlays for dimming the area outside the selection */}
        <div
          className='overlay overlay-top'
          style={{ height: selectionArea.y }}
        ></div>
        <div
          className='overlay overlay-left'
          style={{
            width: selectionArea.x,
            top: selectionArea.y,
            height: selectionArea.height,
          }}
        ></div>
        <div
          className='overlay overlay-right'
          style={{
            left: selectionArea.x + selectionArea.width,
            top: selectionArea.y,
            height: selectionArea.height,
          }}
        ></div>
        <div
          className='overlay overlay-bottom'
          style={{ top: selectionArea.y + selectionArea.height }}
        ></div>
      </>
    )
  )
}

export default SelectionArea
