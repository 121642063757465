import React, { useState, useEffect } from 'react'
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { auth } from './firebase' // Импортируем инициализированный auth
import MainForm from './MainForm'
import Modal from './Modal'
import Coins from './Coins'
import { LoginForm, RegisterForm, ResetForm } from './Forms'
import './AuthComponent.css'
import coin from '../data/coin.svg'

const AuthComponent = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userEmail, setUserEmail] = useState(null)
  const [modalType, setModalType] = useState(null) // 'login' | 'register' | 'reset'
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [authError, setauthError] = useState('')
  const [coins, setCoins] = useState(0)

  const handleEmailChange = (e) => setEmail(e.target.value)
  const handlePasswordChange = (e) => setPassword(e.target.value)

  const fetchRemainingRequests = (email) => {
    fetch(`https://lawmind.pro:5000/get_remaining?email=${email}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.remaining !== undefined) {
          setCoins(data.remaining)
        } else if (data.error) {
          console.error('Error:', data.error)
        }
        console.log('called')
      })
      .catch((error) => {
        console.error('Error fetching remaining requests:', error)
      })
  }

  const handleRegister = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        setauthError('Registration was successful!')
        setModalType(null)
      })
      .catch((error) => setauthError(`Registration error: ${error.message}`))
  }

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setIsLoggedIn(true)
        setUserEmail(userCredential.user.email)
        fetchRemainingRequests(userCredential.user.email)
        setModalType(null)
      })
      .catch((error) => setauthError(`Login error: ${error.message}`))
  }

  const handlePasswordReset = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setauthError('Password recovery email sent!')
        setModalType(null)
      })
      .catch((error) => setauthError(`Error sending email: ${error.message}`))
  }

  const handleLogout = () => {
    signOut(auth).then(() => {
      setIsLoggedIn(false)
      setUserEmail(null)
      fetchRemainingRequests('')
    })
  }

  const closeModal = () => {
    setModalType(null)
    setEmail('')
    setPassword('')
    setauthError('')
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true)
        setUserEmail(user.email)
        fetchRemainingRequests(userEmail)
      } else {
        setIsLoggedIn(false)
        setUserEmail(null)
        fetchRemainingRequests('')
      }
    })
    return () => unsubscribe()
  }, [])
  // useEffect(() => {
  //   console.log('User email updated:', userEmail)
  // }, [userEmail])

  return (
    <div className='appWrapper'>
      <header className='header'>
        <div className='coinsContainer'>
          <img src={coin} alt='coins'></img>
          <div>
            <Coins coins={coins} />
          </div>
        </div>
        <div className='authButtons'>
          {isLoggedIn ? (
            <>
              <span className='userLoginEmail'>{userEmail}</span>
              <button onClick={handleLogout} className='signOutButton'>
                Sign out
              </button>
            </>
          ) : (
            <>
              {/* <div>
                <Coins coins={coins} />
              </div> */}
              <button
                className='loginButton'
                onClick={() => setModalType('login')}
              >
                Sign In
              </button>
              <button
                className='registerButton'
                onClick={() => setModalType('register')}
              >
                Register
              </button>
            </>
          )}
        </div>
      </header>
      <h1>AI Homework</h1>
      <h3>
        write, tell or take a photo of your homework and get a solution from AI
      </h3>
      <MainForm email={userEmail} coins={coins} setCoins={setCoins} />
      <div className='authModalContent'>
        <Modal isOpen={!!modalType} closeModal={closeModal}>
          {modalType === 'login' && (
            <LoginForm
              email={email}
              password={password}
              onEmailChange={handleEmailChange}
              onPasswordChange={handlePasswordChange}
              onLogin={handleLogin}
              authError={authError}
              onReset={() => setModalType('reset')}
            />
          )}
          {modalType === 'register' && (
            <RegisterForm
              email={email}
              password={password}
              onEmailChange={handleEmailChange}
              onPasswordChange={handlePasswordChange}
              onRegister={handleRegister}
              authError={authError}
            />
          )}
          {modalType === 'reset' && (
            <ResetForm
              email={email}
              onEmailChange={handleEmailChange}
              onReset={handlePasswordReset}
              authError={authError}
            />
          )}
        </Modal>
      </div>
    </div>
  )
}

export default AuthComponent
